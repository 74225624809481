/* You can add global styles to this file, and also import other style files */

@import 'theme';
@import '_main';

tr.mat-header-row {
  vertical-align: bottom;
  height: auto !important;
  .mat-sort-header-button {
    text-align: left;
  }
  .mat-header-cell > span {
    display: inline-block;
    margin: 16px 0;
  }
}

.mat-tooltip.note-tooltip {
  max-width: 230px;
  font-size: 12px;
  line-height: 16px;
  padding: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header {
  height: 48px !important;
  padding: 0 0 0 16px !important;
  &:hover {
    background-color: inherit !important;
  }
  &.mat-expanded {
    height: 48px !important;
    padding: 0 0 0 16px;
  }

}

.mat-expansion-panel {
  box-shadow: none !important;
}
.mat-expansion-indicator {
  margin-right: 0 !important;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  &::after {
    padding: 2.5px !important;
    color: black !important;
  }
}

.mat-table .mat-sort-header-content {
  text-align: left;
}
